<div class="search-input">
	<div class="form-group mb-0">
		<form autocomplete="off">
			<input type="search" name="search" aria-label="search field" class="form-control text-white bg-dark" value="" role="presentation"
				[class.d-block]="searchOpen" [(ngModel)]="searchInput" (keyup)="search($event)" [placeholder]="props.hint || ''" />
			<button type="button" class="btn btn-search show-in-mobile"	(click)="toggleSearch()" aria-label="search button">
				<i class="fas fa-search"></i>
			</button>
			<button type="button" class="btn btn-search show-in-web" (click)="search()" aria-label="search button" disabled>
				<i class="fas fa-search"></i>
			</button>
			<button style="background: transparent; border: none;" aria-label="close button" href="javascript:void(0)" class="d-none close-search" (click)="toggleSearch()" [class.d-flex]="searchOpen"
				*ngIf="searchOpen">
				<i class="fa fa-times"></i>
			</button>
		</form>
	</div>
</div>
