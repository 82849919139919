<main role="main" class="flex-shrink-0 middle-section" *ngIf="loadFlag">
	<div class="middle-inner">
		<app-lesson-banner
			*ngIf="visualization"
			[bannerType]="'visualization'"
			[lesson]="visualization"
			[bookmarks]="bookmarks"
			[likes]="likes"
		></app-lesson-banner>

		<section class="lession-section">
			<div class="section-inner">
				<div class="container">
					<div class="row">
						<div class="col-lg-3 col-md-4 br-01">
							<div class="lession-content-left">
								<div class="content">
									<h2>Visualization Information</h2>

									<div class="lession-item">
										<ul>
											<li>
												<h3>Implementation Level</h3>
												<p>{{ visualization?.level }}</p>
											</li>
											<li>
												<h3>Updated</h3>
												<p>
													{{ visualization?.updated_at | date: 'MM/dd/yyyy' }}
												</p>
											</li>
											<li>
												<h3>Version</h3>
												<p>1.0</p>
											</li>
										</ul>
									</div>
								</div>

								<div class="content">
									<h2>Documents</h2>
									<a
										target="_blank"
										[href]="
											doc.document && doc.document?.url.startsWith('https')
												? doc.document?.url
												: 'https://' + doc.document?.url
										"
										*ngFor="let doc of visualization?.Documents"
									>
										<span>
											<i class="far fa-file-alt"></i>
										</span>
										{{ doc.title }}
									</a>
								</div>

								<div class="content">
									<h2>Technical Details</h2>
									<!-- <p>{{ lesson.technicalDetails }}</p> -->

									<app-technical-details
										[resources]="visualization?.hardwareCompatibility"
									></app-technical-details>
								</div>

								<div class="content content-language">
									<h2>Languages</h2>
									<ol>
										<li *ngFor="let lang of visualization?.languages">
											<p>{{ lang.name }}</p>
										</li>
									</ol>
								</div>
							</div>
						</div>

						<div class="col-lg-9 col-md-8">
							<div class="lession-content-right">
								<div class="row">
									<div class="col-lg-6">
										<div class="content">
											<h2>Visualization Description</h2>
											<div
												class="lesson-item-description"
												[innerHTML]="
													visualization?.description | safeHtml | markdown
												"
											>
												{{ visualization?.description }}
											</div>
										</div>

										<div class="content">
											<h2>Features of this Visualization</h2>
											<div
												class="lesson-item-description"
												[innerHTML]="
													visualization?.features | safeHtml | markdown
												"
											></div>
										</div>

										<div class="content">
											<h2>Topics</h2>
											<div
												class="topic-tag"
												[ngClass]="{ 'educator-mode': educatorMode }"
											>
												<ul>
													<li
														*ngFor="let topic of topics"
														(click)="searchForTopic(topic)"
													>
														<button class="btn">{{ topic?.title }}</button>
													</li>
												</ul>
											</div>
										</div>
									</div>

									<div class="col-lg-6">
										<div class="col-lg-12">
											<div class="content-right">
												<h2>How to Use this Visualization</h2>

												<div
													class="lesson-item-description"
													[innerHTML]="
														visualization?.howToUse | safeHtml | markdown
													"
												></div>
											</div>

											<div class="content-right pre-requesti">
												<h2>What is CAPI?</h2>

												<div
													class="lesson-item-description"
													[innerHTML]="
														visualization?.whatIsCAPI | safeHtml | markdown
													"
												></div>
											</div>
										</div>
									</div>
								</div>

								<hr class="bt-01" />
								<div class="lesson-bottom">
									<div class="row">
										<div class="col-lg-12">
											<div class="content">
												<h2>Teach with This Visualization</h2>
												<p>
													There are three ways to teach with this visualization.
													Select the option that best fits the needs of you and
													your learners.
												</p>
											</div>
										</div>
									</div>

									<div class="row row-10">
										<div class="col-lg-4 pad-10" *ngFor="let card of techCards">
											<div class="learn-card">
												<h2>Do you want to</h2>
												<div class="learn-card-body">
													<ul>
														<li *ngFor="let cont of card.card">
															{{ cont.content }}
														</li>
													</ul>
													<div class="learn-card-footer">
														<button
															(click)="handleClick(card.location)"
															class="btn btn-blue"
														>
															{{ card.buttonTitle }}
														</button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</main>

<!-- Model to Duplicate Visualization -->
<div
	class="modal fade modal-auth"
	[ngClass]="toggleModelDuplicate ? 'show' : ''"
	id="modal-dupe"
	[ngStyle]="{ display: toggleModelDuplicate == true ? 'block' : 'none' }"
>
	<div class="modal-dialog modal-dialog-centered">
		<div class="modal-content">
			<div class="modal-header">
				<h2 tabindex="-1" class="modal-title" id="exampleModalLongTitle">
					Looking to start a lesson with this visualization?
				</h2>
				<button
					type="button"
					class="close"
					data-dismiss="modal"
					aria-label="Close"
				>
					<span
						aria-hidden="true"
						(click)="toggleModelDuplicate = !toggleModelDuplicate"
						>&times;</span
					>
				</button>
			</div>
			<div class="modal-body">
				<div class="login-form-main">
					<div class="login-info">
						<p>
							As a temporary solution, our team will need to create a copy of
							the visualization for you. Click the button below and you will
							receive an invitation to collaborate on the lesson containing this
							visualization within 48 hours.
						</p>
						<p style="color: #ed0000;">
							*Note: We will make every effort to respond to your request the
							same day.
						</p>
					</div>
					<button (click)="requestDuplication()" class="btn btn-blue">
						Request a visualization copy
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
<div [ngClass]="toggleModelDuplicate ? 'modal-backdrop fade show' : ''"></div>
<!-- End Model to Duplicate Visualization -->

<!-- Model to Embed Visualization -->
<div
	class="modal fade modal-auth"
	[ngClass]="toggleModelEmbed ? 'show' : ''"
	id="modal-embed"
	[ngStyle]="{ display: toggleModelEmbed == true ? 'block' : 'none' }"
>
	<div class="modal-dialog modal-dialog-centered">
		<div class="modal-content">
			<div class="modal-header">
				<h2 class="modal-title" id="exampleModalLongTitle">
					Embed this visualization
				</h2>
				<button
					type="button"
					class="close"
					data-dismiss="modal"
					aria-label="Close"
				>
					<span
						aria-hidden="true"
						(click)="toggleModelEmbed = !toggleModelEmbed"
						>&times;</span
					>
				</button>
			</div>
			<div class="modal-body">
				<div class="login-form-main">
					<div class="login-info">
						<p>
							Download/print the CAPI Table below. In your Torus or Tour It
							lesson, create a webpage component.
						</p>
						<p class="modal-heading">
							CAPI Table
						</p>
						<a class="modal-link" href="{{ visualization?.capiTableUrl }}"
							>{{ visualization?.title }} CAPI Table</a
						>
						<p class="modal-heading">
							Sim URL
						</p>
						<a class="modal-link" href="{{ visualization?.simUrl }}">{{
							visualization?.simUrl
						}}</a>
						<img class="modal-icon" src="assets/images/link-icon.png" alt="link icon" />
						<img class="modal-icon" src="assets/images/code-icon.png" alt="code icon" />
						<p class="bottom-modal-text">
							Copy/paste the url shown above into the webpage component of
							Torus, then begin explore the CAPI properties using the initial
							state functionality.
						</p>
					</div>
					<button (click)="goToPage(torus_url)" class="btn btn-blue">
						Go to Argos
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
<div [ngClass]="toggleModelEmbed ? 'modal-backdrop fade show' : ''"></div>
<!-- End Model to Embed Visualization -->
