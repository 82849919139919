<!-- <div id="mobile-eductor-bar" class="educator-mobile">
	<div class="educator">
		<p>{{ footerData.educatorModeLabel }}</p>
		<div class="switch-slider">
			<input
				type="checkbox"
				class="toggle"
				id="toggle-mobile"
				[checked]="educatorModeOn"
				(change)="toggleMode()"
			/>
			<label for="toggle-mobile">
				<span class="on">On</span>
				<span class="off">Off</span>
			</label>
		</div>
	</div>
</div> -->
<footer id="app-footer-section" class="footer-section mt-auto" [ngClass]="currentPage == '/search' || currentPage == '/join' ? 'remove-footer-margin' : '' ">
	<div class="footer-inner">
		<div class="footer-content">
			<div class="container-fluid">
				<div class="row">
					<div class="col">
						<div class="row">
							<div class="offset-lg-1 col-12 col-md-9 col-lg-8 listflow left">
								<div *ngFor="let link of footerData.Links">
									<a [target]="link.openInNewTab ? '_blank' : '_self'" [href]="link.linkUrl">{{ link.linkText }}</a>
								</div>
								<div *ngIf="!authenticated"><a href="javascript://" (click)="openSignIn()">Join For Free Access</a></div>
							</div>
							<div class="col-12 col-md-3 col-lg-2 mt-4 mt-lg-0 listflow right">
								<div><a href="https://www.facebook.com/groups/318377162831355" target="_blank" aria-label="Infiniscope's Facebook"><i class="fab fa-facebook" aria-hidden="true"></i></a></div>
								<div><a href="https://www.pinterest.com/infiniscope/hivespace/" target="_blank" aria-label="Infiniscope's Pinterest"><i class="fab fa-pinterest" aria-hidden="true"></i></a></div>
								<div><a href="https://infiniscope.blogspot.com/" target="_blank" aria-label="Infiniscope's Blog"><i class="fab fa-blogger" aria-hidden="true"></i></a></div>
								<div><a href="https://www.youtube.com/infiniscope" target="_blank" aria-label="Infiniscope's YouTube"><i class="fab fa-youtube" aria-hidden="true"></i></a></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="footer-bottom">
			<div class="container-fluid">
				<div class="row">
					<div class="col">
						<div class="row sponsors">
							<div class="offset-lg-1 col-12 col-md col-lg-5 col-left">
								<a routerLink="/"><img src="/assets/images/logo.svg" alt="Infiscope Logo" height="40" /></a>
							</div>
							<div class="col-12 col-md col-lg-5 col-right">
								<a target="_blank" href="https://science.nasa.gov/learn/"><img src="/assets/images/nasa.svg" alt="NASA" height="80" /></a>
								<a target="_blank" href="https://etx.asu.edu/"><img src="/assets/images/asu.svg" alt="ASU ETX" height="40" /></a>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- <div *ngFor="let logo of footerData.logos">
				<a [href]="logo.caption" target="_blank"><img [src]="logo.url" [alt]="logo.alternativeText" /></a>
			</div> -->
		</div>
	</div>
</footer>
