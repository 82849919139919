import { InfoModalComponent } from './../../../../shared/modules/info-modal/info-modal/info-modal.component';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from 'src/app/services/alert/alert.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { ApiService } from 'src/app/services/API/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
	loginForm: FormGroup;
	public loading: boolean = false;
	public forgotPasswordSubmitted: boolean = false;
	submitted = false;
	returnUrl: string;
	errorMsg: string;
	currentPage = '';
	migratedAccountPresent: boolean = false;
	@Input() title: string;
	@Input() forgotPasswordRightAlignment: boolean;

	constructor(
		private formBuilder: FormBuilder,
		private route: ActivatedRoute,
		private router: Router,
		private alertService: AlertService,
		private api: ApiService,
		private auth: AuthenticationService,
		private dialog: MatDialog
	) {}

	public get authenticated() {
		return this.api.authenticated;
	}

	public get user() {
		return this.auth.currentUserValue;
	}

	ngOnInit() {
		this.loginForm = this.formBuilder.group({
			username: ['', Validators.required],
			password: ['', Validators.required]
		});

		// get return url from route parameters or default to '/'
		this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
	}

	// convenience getter for easy access to form fields
	get f() {
		return this.loginForm.controls;
	}

	get placeholderImg() {
		let img: string = '';
		if (this.isNotEmpty(this.user)) {
			if (this.user.role.name == 'Educator') {
				img = 'assets/images/avatar/educator_avatar.png';
			} else if (this.user.role.name == 'Collaborator') {
				img = 'assets/images/avatar/collaborator_avatar.png';
			} else if (this.user.role.name == 'Infiniscope Member') {
				img = 'assets/images/avatar/infiniscope_avatar.png';
			} else if (this.user.role.name == 'Advisory Member') {
				img = 'assets/images/avatar/advisory_avatar.png';
			}
		}
		return img;
	}

	isNotEmpty(obj) {
		if (obj) {
			return Object.keys(obj).length > 0;
		}
		return false;
	}

	async onSubmit() {
		this.submitted = true;
		this.errorMsg = null;
		// stop here if form is invalid
		if (this.loginForm.invalid) {
			const emailError = document.getElementById('email-error');
			const passwordError = document.getElementById('password-error');
			if (emailError) emailError.focus();
			else if (passwordError) passwordError.focus();
			return;
		}

		await this.api
			.get('migrated-users?email=' + this.f.username.value + '&joined=false')
			.toPromise()
			.then(
				(res) => {
					if (res.length > 0) {
						this.migratedAccountPresent = true;
						this.errorMsg =
							'It looks like you had an account on our old site. You\'ll need to create a new account here using your same email. Click the "Create an account" button to get started.';
						const dialog = this.dialog.open(InfoModalComponent, {
							width: '600px',
							panelClass: 'addTagModal',
							data: {
								title: 'Welcome to the new Infiniscope Portal!',
								msg:
									'It looks like you had an account on our old site. You\'ll need to create a new account here using your same email. Click the "Create an account" button to get started.'
							}
						});
					} else {
						this.loading = true;
						this.auth
							.login(this.f.username.value, this.f.password.value)
							.then(
								async (data) => {
									// Check to see if they were imported and are logging in for the first time
									// Ask to change password only once
									if (data.user && data.user.firstLogin && data.user.imported) {
										data.user.firstLogin = false;
										try {
											await this.api.put('users/me', data.user).toPromise();
											this.router.navigate(['/?reset=true']);
										} catch (err) {
											this.dialog.open(InfoModalComponent, {
												width: '600px',
												panelClass: 'addTagModal',
												data: {
													title: 'Sorry! Something went wrong',
													msg: 'We could not log you in, please try again.'
												}
											});
										}
									} else {
										this.router.navigate([this.returnUrl]);
									}
								},
								(error) => {
									if (error?.error?.data[0]?.messages[0]) {
										this.errorMsg = error.error.data[0].messages[0].message;
										this.dialog.open(InfoModalComponent, {
											width: '600px',
											panelClass: 'addTagModal',
											data: {
												title: 'Sorry! Something went wrong',
												msg: this.errorMsg
											}
										});
									} else {
										this.errorMsg =
											'Authentication failed. Please verify your email address and password.';
									}
									this.alertService.error(error);
								}
							);
					}
				},
				(err) => {
					this.errorMsg = 'Sorry! We had an connection issue!';
				}
			);

		this.loading = false;
	}

	closeDropdownMenuButton() {
		setTimeout(() => { // delay required for local testing so menu closed on logout
			let ele = document.getElementById('dropdownMenuButton');;
			ele?.click();
		}, 100);
	}
	gotoRoute(route: string) {
		this.closeDropdownMenuButton();
		this.currentPage = route;
		this.router.navigate(['/' + route]);
	}

	public logout() {
		this.auth.logout();
		// this.auth.triggerModalLogout(); //for testing the modal without timeout
	}
}
