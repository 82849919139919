<div class="user" [ngClass]="{ focused: focused }">
	<div class="user-drop-down user-signin">
		<div class="dropdown">
			<button type="button" id="dropdownMenuButton" aria-label="profile menu"
				class="btn btn-secondary" [ngClass]="{ 'dropdown-toggle': false, 'noauth': !authenticated, 'auth': authenticated }"
				data-toggle="dropdown"
				aria-haspopup="true"
				aria-expanded="false"
				(focus)="focused = true"
				(blur)="focused = false"
			>
				<div *ngIf="!authenticated"><span>Educator </span>Sign In</div>
				<!-- <img *ngIf="!authenticated" id="img-user-profile-icon" class="img-fluid img-user hexagon" alt="Educator Sign In" [src]="placeholderImg" /> -->
				<img *ngIf="authenticated" id="img-user-profile-icon" class="img-fluid img-user circle" alt="user"
					[src]="user?.profilePicture
							? user.profilePicture?.url
							: placeholderImg
						"
					[ngClass]="{ mobile: isMobile }"
				/>
			</button>
			<div id="userDropdown" class="dropdown-menu" aria-labelledby="dropdownMenuButton">
				<div class="login-form-main">
					<div class="login-header">
						<div class="user-info">
							<h2 *ngIf="authenticated">Your Account</h2>
							<h2 *ngIf="!authenticated">Educator Sign In</h2>
						</div>
						<div class="dd-icon badge badge-pill badge-dark">
							<a href="javascript://" aria-label="close" (click)="focused = false"><i class="fa fa-times" aria-hidden></i></a>
						</div>
					</div>
					<app-login></app-login>
				</div>
			</div>
		</div>
	</div>
</div>
