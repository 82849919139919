import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ISubscription } from 'rxjs/Subscription';
import { EventService } from 'src/app/services/event/event.service';
import { HeaderService } from '../../header.service';

@Component({
	selector: 'app-sub-header',
	templateUrl: './sub-header.component.html',
	styleUrls: ['./sub-header.component.scss']
})
export class SubHeaderComponent implements OnInit{
	@Input() logoUrl: string;
	@Input() props: any;
	naviOpen: boolean = false;
	currentPage = '';
	subscription: ISubscription;


	constructor(
		private event: EventService,
		private router: Router,
		private headerService: HeaderService,
	) {
		// Set Current Page
		router.events.subscribe((url: any) => {
			this.currentPage = this.router.url;
		});
		// Receive event and data from another component
		this.subscription = this.event.currentData.subscribe((data: any) => {
			if (data.action === 'set_page') {
				this.currentPage = data.dataobj.page;
			}
		});
		//HeaderService
		this.headerService.getNavOpen().subscribe(open => {
			this.naviOpen = open;
		});

	}

	ngOnInit(): void {}

	// Mobile nav-bar show and hide
	toggleNav() {
		this.naviOpen = !this.naviOpen;
		this.headerService.setNavOpen(this.naviOpen);
		// document.querySelector('.navbar-nav a').setAttribute('tabindex', this.naviOpen ? '0' : '-1');
	}

	// Unsubscribe event before leave component
	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	openLink(link) {
		if (link.openInNewTab) {
			window.open(link.linkUrl, '_blank');
		} else {
			this.router.navigateByUrl(link.linkUrl);
		}
	}

}
