<div class="sub-header" *ngIf="naviOpen" [ngClass]="{ 'visually-hidden': !naviOpen }">
	<div class="p-0 container-fluid">
		<div class="row">
			<div class="offset-lg-1 col-12 col-lg-10 p-0 nav-container">
				<button class="navbar-toggler"
					(click)="toggleNav()" type="button">
					<span class="navbar-toggler-icon"><i class="fas fa-bars"></i></span>
				</button>
				<nav class="collapse1 navbar-collapse sidenav-new-menu" id="mySidenav2" [class.width-menu]="naviOpen">
					<a href="javascript:void(0)" class="closebtn" (click)="toggleNav()">
						<span>
							<span class="menu-bar-logo"><i class="fas fa-bars"></i></span>
							<img src="assets/images/mobile-logo.png" class="menu-mobile-logo" alt="Infiniscope logo" />
						</span>
						<button>
							<span class="menu-bar-close"><i class="fas fa-times"></i></span>
						</button>
					</a>
					<ul class="navbar-nav" *ngIf="props">
						<li class="nav-item" *ngIf="props.firstMenuData">
							<h2 class="menu-label">{{ props.firstMenuData.title }}</h2>
							<ul>
								<li class="" *ngFor="let link of props.firstMenuData.Links" >
									<a href="javascript://" (click)="openLink(link)" tabindex="0">{{ link.linkText }}</a>
								</li>
							</ul>
						</li>
						<li class="nav-item" *ngIf="props.secondMenuData">
							<h2 class="menu-label">{{ props.secondMenuData.title }}</h2>
							<ul>
								<li class="" *ngFor="let link of props.secondMenuData.Links" >
									<a href="javascript://" (click)="openLink(link)" tabindex="0">{{ link.linkText }}</a>
								</li>
							</ul>
						</li>
						<li class="nav-item" *ngIf="props.thirdMenuData">
							<h2 class="menu-label">{{ props.thirdMenuData.title }}</h2>
							<ul>
								<li class="" *ngFor="let link of props.thirdMenuData.Links" >
									<a href="javascript://" (click)="openLink(link)" tabindex="0">{{ link.linkText }}</a>
								</li>
							</ul>
						</li>
						<li class="nav-item" *ngIf="props.fourthMenuData">
							<h2 class="menu-label">{{ props.fourthMenuData.title }}</h2>
							<ul>
								<li class="" *ngFor="let link of props.fourthMenuData.Links" >
									<a href="javascript://" (click)="openLink(link)" tabindex="0">{{ link.linkText }}</a>
								</li>
							</ul>
						</li>
						<li class="nav-item" *ngIf="props.fifthMenuData">
							<h2 class="menu-label">{{ props.fifthMenuData.title }}</h2>
							<ul>
								<li class="" *ngFor="let link of props.fifthMenuData.Links" >
									<a href="javascript://" (click)="openLink(link)" tabindex="0">{{ link.linkText }}</a>
								</li>
							</ul>
						</li>
					</ul>
				</nav>
				<!-- layer mobile and ipad menu -->
				<!-- <div id="cd-shadow-layer" onclick="closeNav2()" style="display: none;"></div> -->
			</div>
		</div>
	</div>
</div>
