<!-- Begin page content -->
<main role="main" class="flex-shrink-0 middle-section">
	<div class="middle-inner">
		<section class="signup-section">
			<!-- <div class="signup-bg"></div> -->
			<div class="signup-inner">
				<div class="container container-960">
					<!-- sign up -info -->
					<div class="signup-info">
						<div class="row">
							<div class="col-lg-12"><h2>Request a free educator account</h2></div>
							<div class="col-lg-6">
								<div class="signup-info-box">
									<div class="info-icon"><img alt="" src="/assets/images/icon/stars.svg" /></div>
									<div class="info-content">
										<p><b class="title">Exclusive Content</b></p>
										<p>Access to more lessons, virtual field trips, and visualizations.</p>
									</div>
								</div>
							</div>
							<div class="col-lg-6">
								<div class="signup-info-box">
									<div class="info-icon"><img alt="" src="/assets/images/icon/stars.svg" /></div>
									<div class="info-content">
										<p><b class="title">Creator Tools</b></p>
										<p>Build your own virtual field trips and adaptive lessons.</p>
									</div>
								</div>
							</div>
							<div class="col-lg-6">
								<div class="signup-info-box">
									<div class="info-icon"><img alt="" src="/assets/images/icon/stars.svg" /></div>
									<div class="info-content">
										<p><b class="title">Members-Only Events</b></p>
										<p>Invitations to mission science and NASA events.</p>
									</div>
								</div>
							</div>
							<div class="col-lg-6">
								<div class="signup-info-box">
									<div class="info-icon"><img alt="" src="/assets/images/icon/stars.svg" /></div>
									<div class="info-content">
										<p><b class="title">Professional Development</b></p>
										<p>Training on Infiniscope resources and best practices.</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- end -->

					<div class="signup-form">
						<form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
							<div class="signup-body">
								<p>Fill out the form below and start teaching with Infiniscope today!</p>

								<div class="form-group">
									<label for="firstName">Name *</label>
									<input aria-required="true" id="firstName" class="form-control" placeholder="First name" autocomplete="name"
										formControlName="firstName" [ngClass]="{
											'is-invalid': f.firstName.errors && f.firstName.touched
										}" />
									<div *ngIf="f.firstName.errors && f.firstName.touched" class="invalid-feedback">
										<div *ngIf="f.firstName.errors.required">
											<span aria-label="First Name is required" aria-live="assertive">
												First Name is required
											</span>
										</div>
									</div>
								</div>
								<div class="form-group">
									<label class="hide-label" for="lastName">Last Name *</label>
									<input aria-required="true" id="lastName" class="form-control" placeholder="Last name" autocomplete="additional-name"
										formControlName="lastName" [ngClass]="{
											'is-invalid': f.lastName.errors && f.lastName.touched
										}" />
									<div *ngIf="f.lastName.errors && f.lastName.touched" class="invalid-feedback">
										<div *ngIf="f.lastName.errors.required">
											<span aria-live="assertive">
												Last Name is required
											</span>
										</div>
									</div>
								</div>

								<div class="form-group">
									<label for="email">Email *</label>
									<input aria-required="true" id="email" type="email" class="form-control" placeholder="Email Address"
										formControlName="email" [ngClass]="{
											'is-invalid': f.email.errors && f.email.touched
										}" />
									<div *ngIf="f.email.errors && f.email.touched" class="invalid-feedback">
										<div *ngIf="f.email.errors.required">
											<span aria-live="assertive">
												Email is required
											</span>
										</div>
									</div>
								</div>

								<div class="form-group">
									<label for="password">Password *</label>
									<input aria-required="true" id="password" type="password" class="form-control" placeholder="Password"
										formControlName="password" [ngClass]="{
											'is-invalid': f.password.errors && f.password.touched
										}" />
									<div *ngIf="f.password.errors && f.password.touched" class="invalid-feedback">
										<div *ngIf="f.password.errors.required">
											<span aria-live="assertive">
												Password is required
											</span>
										</div>
										<div *ngIf="f.password.errors.pattern">
											<span aria-live="assertive">
												Passwords must be a minimum of 9 characters, start with a letter and include upper and lowercase letters, a special character !@$%&*, and a number.
											</span>
										</div>
										<div *ngIf="f.password.errors.minLength">
											<span aria-live="assertive">
												Passwords must have at least 8 characters
											</span>
										</div>
									</div>
								</div>

								<div class="form-group">
									<label for="confirm-pass" class="hide-label">Confirm Password *</label>
									<input aria-required="true" id="confirm-pass" type="password" class="form-control" placeholder="Confirm Password"
										formControlName="confirmPassword" [ngClass]="{
											'is-invalid':
												f.confirmPassword.errors && f.confirmPassword.touched
										}" />
									<div *ngIf="
											(f.confirmPassword.errors ||
												(f.confirmPassword.value !== f.password.value)) &&
											f.confirmPassword.touched" class="invalid-feedback">
										<div *ngIf="
												f.confirmPassword.errors.required &&
												!f.confirmPassword.value
											">
											<span aria-live="assertive">
												Confirm password is required
											</span>
										</div>
										<div *ngIf="f.confirmPassword.value !== f.password.value">
											<span aria-live="assertive">
												Confirm password does not match the password
											</span>
										</div>
									</div>
								</div>

								<hr />

								<div class="form-group">
									<label for="eduIns">Educational institution *</label>
									<input aria-required="true"  id="eduIns" class="form-control" placeholder="Name" formControlName="instituteName" [ngClass]="{
											'is-invalid':
												f.instituteName.errors && f.instituteName.touched
										}" />
									<div *ngIf="f.instituteName.errors && f.instituteName.touched" class="invalid-feedback">
										<div *ngIf="f.instituteName.errors.required">
											<span aria-live="assertive">
												Name is required
											</span>
										</div>
									</div>
								</div>

								<div class="form-group">
									<label for="instituteType" class="d-none">Institute Type</label>
									<select id="instituteType" class="form-control" id="instituteType" formControlName="instituteType"
										[ngClass]="{
											'is-invalid':
												f.instituteType.errors && f.instituteType.touched,
											'is-untouched': !f.instituteType.touched
										}" aria-label="Institute Type">
										<option hidden [ngValue]="''" selected>Type</option>
										<option [value]="instituteType" *ngFor="let instituteType of instituteTypes">{{ instituteType }}
										</option>
									</select>
									<div *ngIf="f.instituteType.errors && f.instituteType.touched" class="invalid-feedback">
										<div *ngIf="f.instituteType.errors.required">
											<span aria-live="assertive">
												Type is required
											</span>
										</div>
									</div>
								</div>

								<div class="form-group">
									<label for="city" class="hide-label">City</label>
									<input aria-required="true" class="form-control" placeholder="City" formControlName="instituteCity" [ngClass]="{
											'is-invalid':
												f.instituteCity.errors && f.instituteCity.touched
										}" aria-label="Institute City"/>
									<div *ngIf="f.instituteCity.errors && f.instituteCity.touched" class="invalid-feedback">
										<div *ngIf="f.instituteCity.errors.required">
											<span aria-live="assertive">
												City is required
											</span>
										</div>
									</div>
								</div>

								<div class="form-group">
									<!-- <input class="form-control" placeholder="State" formControlName="instituteState" [ngClass]="{
											'is-invalid':
												f.instituteState.errors && f.instituteState.touched
										}" /> -->
									<label class="hide-label" for="instState">Institute State</label>
									<select id="instState" class="form-control" id="instituteState" formControlName="instituteState"
										[ngClass]="{
											'is-invalid':
												f.instituteState.errors && f.instituteState.touched,
											'is-untouched': !f.instituteState.touched
										}" aria-label="Institute State">
										<option hidden [ngValue]="''" selected>State</option>
										<option [value]="state" *ngFor="let state of states">{{ state }}
										</option>
									</select>
									<div *ngIf="f.instituteState.errors && f.instituteState.touched" class="invalid-feedback">
										<div *ngIf="f.instituteState.errors.required">
											<span aria-live="assertive">
												State is required
											</span>
										</div>
									</div>
								</div>

								<div class="form-group">
									<label for="zip" class="hide-label">Zip</label>
									<input aria-required="true"  id="zip" class="form-control" placeholder="Zip" formControlName="instituteZip" [ngClass]="{
											'is-invalid':
												f.instituteZip.errors && f.instituteZip.touched
										}" aria-label="Institute Zip"/>
									<div *ngIf="f.instituteZip.errors && f.instituteZip.touched" class="invalid-feedback">
										<div *ngIf="f.instituteZip.errors.required">
											<span aria-live="assertive">
												Zip is required
											</span>
										</div>
									</div>
								</div>

								<hr />

								<div class="form-group">
									<label for="subject-taught">Subject taught</label>
									<input aria-required="true"  id="subject-taught" class="form-control" placeholder="Subject(s)"
										formControlName="subjectTaught" [ngClass]="{
											'is-invalid':
												f.subjectTaught.errors && f.subjectTaught.touched
										}" />
									<div *ngIf="
											f.subjectTaught.errors && f.subjectTaught.touched
										" class="invalid-feedback">
										<div *ngIf="f.subjectTaught.errors.required">
											Subject is required
										</div>
									</div>
								</div>

								<div class="form-group">
									<h2 class="grade-text">Grade level *</h2>
									<ol class="group-checkbox" [ngClass]="{'is-invalid': !validGradeLevel && gradeLevelTouched}">
										<li class="custom-control custom-checkbox" *ngFor="let gradeLevel of gradeLevelLabel">
											<input type="checkbox" class="custom-control-input" id="{{ gradeLevel.value }}"
												(click)="updateGradeLevel(gradeLevel.value)" [(checked)]="gradeLevel.selected" />
											<label class="custom-control-label btn p-0" for="{{ gradeLevel.value }}">{{ gradeLevel.title
												}}</label>
										</li>
									</ol>
									<div *ngIf="!validGradeLevel && gradeLevelTouched" class="invalid-feedback d-block">
										Grade level is required
									</div>
								</div>

								<div class="form-group">
									<h2 class="grade-text">Which of the following are you interested in? *</h2>
									<ol class="group-checkbox" [ngClass]="{'is-invalid': !validInterestedIn && interestedInTouched}">
										<li class="custom-control custom-checkbox" *ngFor="let interestedIn of interestedInLabel">
											<input type="checkbox" class="custom-control-input" id="{{ interestedIn.value }}"
												(click)="updateInterestedIn(interestedIn.value)" [(checked)]="interestedIn.selected" />
											<label class="custom-control-label btn p-0" for="{{ interestedIn.value }}">{{ interestedIn.title
												}}</label>
										</li>
									</ol>
									<div *ngIf="!validInterestedIn && interestedInTouched" class="invalid-feedback d-block">
										Interested In is required
									</div>
								</div>

								<hr />

								<div class="form-group">
									<label for="hearAbout">How did you hear about us?</label>
									<!-- <select id="hearAbout" class="form-control" placeholder="How you did you hear about us?"
										formControlName="hearAbout" [ngClass]="{
											'is-invalid': f.hearAbout.errors && f.hearAbout.touched,
											'is-untouched': !f.instituteType.touched
										}">
										<option hidden [ngValue]="''" selected>How you did you hear about us?</option>
										<option [value]="hearAbout" *ngFor="let hearAbout of hearAbouts">{{ hearAbout }}</option>
									</select> -->
									<textarea aria-required="true" class="form-control" cols="30" rows="10"
										id="hearAbout" formControlName="hearAbout" name=""
										placeholder="Tell us how you found out about us."></textarea>
								</div>

								<div class="form-group">
									<label for="reasonJoining">Reason for joining *</label>
									<textarea aria-required="true" class="form-control"cols="30" rows="10"
										id="reasonJoining" formControlName="reasonJoining" name=""
										[ngClass]="{ 'is-invalid': f.reasonJoining.errors && f.reasonJoining.touched }"
										placeholder="Tell us why you are interested..."></textarea>
								</div>
							</div>

							<div class="signup-footer">
								<div class="row">
									<div class="col-lg-6">
										<div class="captcha">
											<re-captcha (resolved)="recaptchaCallback($event)" [siteKey]="recaptchaSiteKey"></re-captcha>
										</div>
									</div>
									<div class="col-lg-6">
										<div class="captcha-info">
											<p>
												By requesting educator access, you agree to our Terms
												and Privacy Policy
											</p>
										</div>
										<div *ngIf="isForInvalid()" role="alert" aria-live="assertive" class="fill-required">
											Please fill out required fields.
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-lg-12">
										<div class="btn-groups signup-btns">
											<button class="btn btn-primary btn-blue" [disabled]="!recaptcha">
												Request Educator Access
											</button>
										</div>
									</div>
								</div>
							</div>
						</form>
						<div class="educator-not">
							<div class="row">
								<div class="col-lg-12 text-center">
									<p>Not an educator? You can access <a href="/">exploratory activities</a> from the home page without having to sign up!</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</main>
