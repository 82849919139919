<header class="header header-color2">
	<a href="#app-header" class="skipnav">Skip nav</a>
	<nav id="app-header" class="navbar navbar-expand-md navbar-dark" #stickyMenu [class.fixed-top]="sticky">
		<div class="main-header">
			<div class="container-fluid">
				<div class="row row-100 justify-content-center col-header">

						<div class="col-5 col-md-2 justify-content-left logo-container sticky">
							<div class="container p-0">
								<a routerLink="/" aria-label="Infiscope Logo" class="navbar-brand show-in-mobile">
									<img src="assets/images/mobile-logo.png" class="menu-logo" alt="Infiscope Logo" />
								</a>
								<!-- <a routerLink="/" aria-label="Infiscope Logo" class="navbar-brand show-in-web">
									<img src="/assets/images/logo.svg" class="menu-logo" alt="Infiscope Logo" />
								</a> -->
								<a routerLink="/" aria-label="Infiscope Logo" class="show-in-web">
									<img src="/assets/images/logo.svg" class="menu-logo" alt="Infiscope Logo" />
								</a>
							</div>
						</div>
						<div class="col-1 col-md-6">
							<div class="d-flex justify-content-end">
								<div class="w-100 search-bar">
									<!-- Educator Mode -->
									<!-- <app-educator-mode [props]="{label: headerData?.educatorModeLabel}"></app-educator-mode> -->
									<!-- Search Bar -->
									<app-search-bar class="mw-475" [props]="{hint: headerData?.searchBoxHint}"></app-search-bar>
								</div>
								<!-- <app-notification></app-notification> -->
							</div>
						</div>
						<div class="col-6 col-md-4 col-lg-3">
								<div class="d-flex justify-content-end">
									<div class="menu-icon">
										<button type="button" class="btn btn-secondary navbar-toggler-remove" style="background: transparent; border: 0;"
											(click)="toggleHam()" [attr.aria-expanded]="hamOpen" aria-label="Toggle navigation menu">
											<span class="navbar-toggler-icon-remove">
												<i class="fas fa-bars" aria-hidden="true"></i> <!--[ngClass]="{ 'fa-layer-group': hamOpen, 'fa-bars': !hamOpen }" -->
											</span>
										</button>
									</div>
									<div class="menu-icon" *ngIf="authenticated">
										<app-tools></app-tools>
									</div>
									<div class="menu-icon">
										<app-profile></app-profile>
									</div>
								</div>
						</div>

				</div>
			</div>
		</div>

		<!-- Sub Header -->
		<app-sub-header class="w-100"
			[props]="{
				firstMenuData: headerData?.FirstHeaderMenu,
				secondMenuData: headerData?.SecondHeaderMenu,
				thirdMenuData: headerData?.ThirdHeaderMenu,
				fourthMenuData: headerData?.FourthHeaderMenu,
				fifthMenuData: headerData?.FifthHeaderMenu
			}"
			[logoUrl]=""
			[ngClass]=""
		>
		</app-sub-header>	

	</nav>
</header>
<router-outlet></router-outlet>
