<div class="tools" [ngClass]="{ focused: focused }">
	<div class="tool-drop-down user-signin">
		<div class="dropdown">
			<button
				class="btn btn-secondary badge badge-pill"
				type="button"
				id="dropdownToolsButton"
				data-toggle="dropdown"
				aria-haspopup="true"
				aria-expanded="false"
				(focus)="focused = true"
				(blur)="focused = false"
				aria-label="tools menu"
			>
				<mat-icon class="external-icon">apps</mat-icon>
			</button>
			<div class="dropdown-menu" aria-labelledby="dropdownToolsButton">
				<div class="tool-popup" [ngClass]="{'not-signed': !authenticated}">
					<div class="tool-wrap">
						<div class="top-bars">
							<div class="title-tool"><h2>Tools</h2></div>
							<div class="tool-icon badge badge-pill badge-dark">
								<mat-icon>apps</mat-icon>
							</div>
						</div>
						<div class="list-wrapper">
							<div class="list-tool">
								<h3>Creative Tools</h3>
								<ul>
									<li class="opt-sign" *ngFor="let tool of creative">
										<a *ngIf="tool.externalLink && !(tool.url.includes('argos') || tool.url.includes('tourit'))" [href]="tool.url" target="_blank">
											<img [src]="tool.logo.url" [alt]="tool.logo.alternativeText"/><br/>
											<span>{{tool.name}}</span>
										</a>
										<a class="partner-link" *ngIf="tool.externalLink && (tool.url.includes('argos') || tool.url.includes('tourit'))" (click)="partnerLinkClicked(tool.url)" tabindex="0">
											<img [src]="tool.logo.url" [alt]="tool.logo.alternativeText"/><br/>
											<span>{{tool.name}}</span>
										</a>
										<a *ngIf="!tool.externalLink" [routerLink]="[tool.url]" routerLinkActive="router-link-active">
											<img [src]="tool.logo.url" [alt]="tool.logo.alternativeText"/><br/>
											<span>{{tool.name}}</span>
										</a>
									</li>

								</ul>
							</div>
							<div class="list-tool">
								<h3>Hivespace Community</h3>
								<ul>

									<li class="opt-sign" *ngFor="let tool of hivespace" [ngClass]="{'ignore-unauth': tool.allowUnauthorized}">
										<a *ngIf="tool.externalLink" [href]="tool.url + '?id_token=' + this.localStorage.getItem('cognito_id_token') + '&error_url=' + domain + router.url" target="_blank">
											<img [src]="tool.logo.url" [alt]="tool.logo.alternativeText"/><br/>
											<span>{{tool.name}}</span>
										</a>
										<a *ngIf="!tool.externalLink" [routerLink]="[tool.url]" routerLinkActive="router-link-active">
											<img [src]="tool.logo.url" [alt]="tool.logo.alternativeText"/><br/>
											<span>{{tool.name}}</span>
										</a>
									</li>

								</ul>
							</div>
							<button aria-label="learn more" class="btn btn-primary w-100" [routerLink]="['/tools']">Learn More</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
