import { SocialMediaModalComponent } from './../../../shared/modules/social-media-modal/social-media-modal/social-media-modal.component';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/API/api.service';
import { EventService } from 'src/app/services/event/event.service';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
	public educatorModeOn: boolean = this.event.educatorMode;
	currentPage = '';
	footerData: any = {}

	public get authenticated(): boolean {
		return this.api.authenticated;
	}

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private event: EventService,
		private api: ApiService,
		private dialog: MatDialog
	) {
		// Receive event and data from another component
		this.event.currentData.subscribe((data: any) => {
			if (data.action === 'set_flag_mode') {
				this.educatorModeOn = data.dataobj.educatorModeOn;
			}
		});
	}

	ngOnInit(): void {
		this.api
			.get('footer', null)
			.subscribe((data) => {
				this.footerData = data;
				// console.log(this.footerData)
			});
	}

	openSignIn() {
		setTimeout(() => {
			let ele = document.getElementById('dropdownMenuButton');
			ele?.click();
		}, 100);
	}

	// On change mode
	toggleMode() {
		this.educatorModeOn = !this.educatorModeOn;
		this.event.educatorMode = this.educatorModeOn;

		const ob = {
			action: 'set_flag_mode',
			dataobj: { educatorModeOn: this.educatorModeOn, page: this.router.url }
		};
		this.event.globalEvent(ob);
	}

	// Redirect to another route or page
	gotoRoute(route: string) {
		this.currentPage = route;
		this.router.navigate(['/' + route]);
	}

	openSocialMediaModal() {
		const dialogRef = this.dialog.open(SocialMediaModalComponent, {
			width: '600px',
			panelClass: 'addTagModal',
		});
	}
}
