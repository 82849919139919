<div class="slider-container">
	<div class="slider" [ngClass]="{ active: bannerIndex === i }"
		[ngStyle]="{ 'background-image': 'url(' + (heroBanner.animatedGif || heroBanner.heroImage.url) + ')' }"
		*ngFor="let heroBanner of heroBanners; let i = index">
		<div class="gradient-overlay"></div>
		<div class="row w-100 mx-0 p-3">
			<div class="col-2 mx-0 px-0 nav-menu" *ngIf="windowWidth > 767">
				<!-- <button class="nav-arrow" (click)="moveIndex(-1)" [attr.aria-label]="'You are currently on slide ' + i + '. Navigate to previous slide'" aria-live="assertive">
					<span aria-hidden="true" class="material-icons">
						navigate_before
					</span>
				</button> -->
			</div>

			<div class="row mx-0 p-3 description" [ngClass]="{
					'col-md-6 col-sm-12': windowWidth <= 1600,
					'col-md-4 col-sm-12': windowWidth > 1600
				}">
				<div class="col-12 mx-0 px-0 pb-2 title">
					<h2 [attr.aria-valuenow]="'Slide number ' + i + '. ' + heroBanner.title">{{ heroBanner.title }}</h2>
				</div>

				<div class="col-12 mb-3 mx-0 px-0 content" [innerHTML]="heroBanner.description | safeHtml | markdown">
				</div>

				<button class="btn launch px-4" target="_self" (click)="openUrl(heroBanner.url)">{{ heroBanner.buttonTitle || 'Launch' }}</button>

			</div>

			<div class="mx-0 px-0" [ngClass]="{
					'col-2': windowWidth <= 1600,
					'col-4': windowWidth > 1600
				}"></div>

			<div class="col-2 mx-0 px-0 nav-menu" *ngIf="windowWidth > 767">
				<!-- <button class="nav-arrow" (click)="moveIndex(1)" [attr.aria-label]="'You are currently on slide ' + i + '. Navigate to next slide'" aria-live="assertive">
					<span aria-hidden="true" class="material-icons">
						navigate_next
					</span>
				</button> -->
			</div>
		</div>
	</div>

	<div class="dot-nav col-12 mx-0 px-0 text-center">
		<div class="play-stop">
			<button  *ngIf="!playButton;else playBtn" (click)="stop()" aria-label="Stop/pause carousel">
				<i class="fas fa-pause" aria-hidden="true"></i>
			</button>
			<ng-template #playBtn>
				<button (click)="play()" aria-label="Play carousel">
					<i class="fas fa-play" aria-hidden="true"></i>
				</button>
			</ng-template>
		</div>
		<ng-container *ngFor="let heroBanner of heroBanners; let i = index">
			<button [attr.aria-label]="'Slide number ' + i" class="dot" [ngClass]="{ active: bannerIndex === i }"
				(click)="moveIndex(i - bannerIndex)"></button>
		</ng-container>
	</div>
</div>
