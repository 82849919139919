import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class HeaderService {
    private navOpenSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor() { }

    // Observable for naviOpen state
    getNavOpen(): Observable<boolean> {
    	return this.navOpenSubject.asObservable();
    }
    // Method to update naviOpen state
    setNavOpen(value: boolean): void {
    	this.navOpenSubject.next(value);
    	// console.log('header.service', this.navOpenSubject);
    }
}
